@import "../../responsive/breakpoints";

// plan hero section
.tron_front_planPDF_hero_wrapper {
  background: linear-gradient(#0e0529, #1e0b56);
  padding-top: 100px;
  padding-bottom: 80px;
  @include breakpoints(tablet) {
    padding-bottom: 20px;
  }
  .tron_front_planPDF_hero_container {
    width: 100%;
    .all_pdf_title {
      width: 100%;
      background: rgba(36, 35, 35, 0);
      padding: 10px 10px;
      form {
        margin: 30px 0px;
        span {
          margin-top: 3px;
        }
      }
    }
    .pdf_container {
      width: 100%;
      height: 80vh;
      .classDocViewer {
        width: "50%";
        height: "100%";
        margin: "0px auto";
      }
    }
  }
}
