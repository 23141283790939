@import "../../responsive/breakpoints";
// stiky header css
.sticky {
  background-color: #0e0524 !important;
  transition: all 0.3s ease-in-out;
}
.tron_frontpage_header_wrapper {
  background-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  padding: 0px 0px;
  @include breakpoints(miniSemiTablet) {
    background-color: #0e0524 !important;
  }
  .toggler_icon {
    display: none;
    @include breakpoints(miniSemiTablet) {
      display: block;
      margin-right: 15px;
      svg {
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .tron_front_navbar {
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tron_front_logo {
      @include breakpoints(miniSemiTablet) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .logo {
        img {
          width: 100px;
          height: 50px;
          @include breakpoints(mdlgMobile) {
            width: 80px;
            height: 35px;
          }
        }
      }
      .tron_front_navbar_lists2 {
        display: none;
        @include breakpoints(miniSemiTablet) {
          display: inline-block;
          display: flex;
          gap: 10px;
        }
        list-style: none;
        .tron_front_navbar_list2 {
          display: inline-block;
          margin-left: 0px;
          .tron_front_nav_link2 {
            font-size: 13px;
            padding: 10px;
            font-weight: 600;
            color: #fff;
            transition: all 0.3s ease-in-out;
            @include breakpoints(mobile) {
              font-size: 12px;
              padding: 6px;
              font-weight: 600;
            }
          }
          .login2 {
            border: 0px;
            background-color: #e8c743;
            &:hover {
              color: #fff !important;
              background-color: #c4a50c;
              border-bottom: none !important;
            }
          }
          .register2 {
            border: 0px;
            background-color: #ffffff;
            color: #1e0b56 !important;
            &:hover {
              color: $text-white !important;
              background-color: #787af0;
              border-bottom: none !important;
            }
          }
          .tron_front_navbar_dashboard{
            border: 0px;
            background-color: #ffffff;
            color: #1e0b56 !important;
            &:hover {
              color: #fff !important;
              background-color: #787af0;
              border-bottom: none !important;
            }
          }
          .tron_front_navbar_logOut{
            border: 0px;
            background-color: #e8c743;
            &:hover {
              color: #fff !important;
              background-color: #c4a50c;
              border-bottom: none !important;
            }
          }
        }
      }
    }
    @include breakpoints(miniSemiTablet) {
      justify-content: start;
    }
    .toggle_navbar {
      @include breakpoints(miniSemiTablet) {
        height: 220px !important;
        opacity: 1 !important;
        transition: all 0.3s ease-in-out !important;
        transform: scaleY(1.1) !important;
        background: #0e0524 !important;
        transform-origin: top !important;
      }
    }
    .tron_front_navbar_menu {
      transition: all 0.3s ease-in-out;
      @include breakpoints(miniSemiTablet) {
        position: absolute;
        top: 57px;
        width: 100%;
        background: #0e0524;
        left: 0;
        height: 0px;
        opacity: 0;
        border-top: 2px solid #7b5eea;
        transition: all 0.3s ease-in-out;
        transform: scaleY(0);
      }
      @include breakpoints(mdlgMobile) {
        top: 45px;
      }
      .tron_front_navbar_lists {
        list-style: none;
        @include breakpoints(miniSemiTablet) {
          margin-left: 15px !important;
        }
        @include breakpoints(mdlgMobile) {
          margin-left: 10px !important;
        }
        .tron_front_navbar_list {
          display: inline-block;
          margin-left: 15px;
          @include breakpoints(semiTablet) {
            margin-left: 5px !important;
          }
          @include breakpoints(miniSemiTablet) {
            display: inherit !important;
            margin: 13px 30px !important;
          }
          .tron_front_nav_link {
            font-size: 13px;
            font-weight: 600;
            color: #fff;
            padding: 10px;
            @include breakpoints(semiTablet) {
              padding: 10px 8px;
            }
            // background-color: red;
            transition: all 0.3s ease-in-out;
            &:hover {
              border-bottom: 2px solid #7b5eea;
              color: #7b5eea;
            }
            &:focus + .sub-menu-l {
              display: block;
              position: absolute;
              background-color: rgba(28, 28, 85, 0.79);
              margin-top: 15px;
              margin-left: 0px;
              ul {
                list-style: none;
                margin: 0px;
                @include breakpoints(miniSemiTablet) {
                  text-align: center;
                }
                li {
                  padding: 10px 30px;
                  // pointer-events:none;
                  cursor: pointer;
                  &:hover {
                    background-color: #ae989877;
                    // border-bottom: 2px solid #e8c743;
                    // color: #e8c743;
                  }
                  a {
                    // padding: 0px 15px;
                    font-size: 12px;
                    border-bottom: 2px solid rgba(0, 0, 0, 0);
                    @include breakpoints(largeMobile) {
                      padding: 8px;
                    }
                    color: #fff;
                    &:hover {
                      border-bottom: 2px solid #e8c743;
                      color: #e8c743;
                    }
                  }
                }
              }
            }
          }
          .active {
            border-bottom: 2px solid #7b5eea;
            color: #7b5eea;
          }
          .sub-menu-l {
            display: none;
          }
        }
        .login {
          @include breakpoints(miniSemiTablet) {
            margin-top: 25px !important;
          }
        }

        .login,
        .register {
          a {
            padding: 8px 28px !important;
            transition: all 0.3s ease-in-out;
            border-radius: 0px;
            font-weight: 400 !important;
            color: #1e0b56 !important;
            background-color: #e8c743;
            &:hover {
              color: #fff !important;
              background-color: #c4a50c;
              border-bottom: none !important;
            }
            @include breakpoints(miniSemiTablet) {
              display: none;
            }
          }
        }
        .register {
          @include breakpoints(miniSemiTablet) {
            margin-top: 25px !important;
          }
          .active {
            border-bottom: none;
            color: #7b5eea;
          }
          a {
            color: #7b5eea !important;
            background-color: #ffffff !important;
            &:hover {
              color: #fff !important;
              background-color: #787af0 !important;
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }
}
