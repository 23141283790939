.mono_reward_page_wrapper {
  margin-top: 20px;
  .mono_reward_form_card {
    padding: 25px;
    min-height: 250px;
    .mono_section_title {
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        color: $text-default-color;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.UserEarning_wallet_page_wrapper {
  .UserEarning_dash_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top: 20px;
    // height: 140px;
    margin-right: 10px;
    @include breakpoints(4kdevice) {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 15px;
    }
    @include breakpoints(tablet) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 15px;
    }

    .LevelIncome_UserIncomeCard_dash_card_wrapper {
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-content: space-between;
      padding: 30px 30px;
      background-color: $bg-white;
      transition: all 0.3s ease-in-out;
      box-shadow: $box-shadow-1;
      // border-right: $border-1;
      border-radius: 5px;
      @include breakpoints(laptop) {
        // height: 60px;
      }
      @include breakpoints(tablet) {
        height: auto;
      }
      .LevelIncome_UserIncomeCard_content {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease-in-out;
        justify-content: space-between;
        .LevelIncome_UserIncomeCard_widget_info {
          p {
            font-size: 30px;
            color: $text-p-color;
            white-space: nowrap;
            @include breakpoints(semiLargeLaptop) {
              font-size: 28px;
            }
            @include breakpoints(tablet) {
              font-size: 26px;
            }
            @include breakpoints(semiMiniTablet) {
              font-size: 24px;
            }
            @include breakpoints(largeMiniMobile) {
              font-size: 20px;
            }
            @include breakpoints(mobile) {
              font-size: 18px;
            }
          }
          h2 {
            font-size: 18px;
            color: $text-p-color;
            margin-bottom: 10px !important;
            @include breakpoints(semiLargeLaptop) {
              // font-size: 16px;
            }
          }
          a {
            color: $text-black;
            font-size: 13px;
            text-transform: capitalize;
            font-weight: 500;
            &:hover {
              color: $text-menu-active-color;
            }
          }
        }
        .LevelIncome_UserIncomeCard_widget_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 0px;
          margin-right: 20px;
          order: 2;
          .LevelIncomeImageIcon{
            width: 45px;
            padding: 15px;
            @include breakpoints (largeMiniMobile){
              width: 35px;
              padding: 12px;
            }
            @include breakpoints (mobile){
              width: 30px;
              padding: 10px;
            }
          }
          @include breakpoints(smLaptop) {
            margin-right: 10px;
          }
          svg {
            color: #fff;
            font-size: 50px;
            border-radius: 50px;
            padding: 10px;
            @include breakpoints(semiLargeLaptop) {
              font-size: 40px;
            }
            @include breakpoints(semiMiniTablet) {
              font-size: 35px;
            }
            @include breakpoints(largeMiniMobile) {
              font-size: 30px;
            }
            @include breakpoints(mdlgMobile) {
              font-size: 25px;
            }
            @include breakpoints(mobile) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
