@import "../../abstract/variables";
@import "../../responsive/breakpoints";
.preview_image {
  .img_preview {
    width: 400px !important;
    height: 390px;
    margin-bottom: 20px !important;
    @include breakpoints(portraitTablet) {
      width: 100% !important;
      height: 250px !important;
    }
    .popupimage {
      width: 100%;
      height: 100%;
    }
  }
}
