.wallet_page_wrapper {
  .tronFlow_dash_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top: 20px;
    // height: 140px;
    margin-right: 10px;
    @include breakpoints(4kdevice) {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 15px;
    }
    @include breakpoints(portraitTablet) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 15px;
    }

    .tronFlow_dash_card_wrapper {
      position: relative;
      padding: 30px 30px;
      background-color: $bg-white;
      transition: all 0.3s ease-in-out;
      box-shadow: $box-shadow-1;
      // border-right: $border-1;
      border-radius: 5px;
      @include breakpoints(laptop) {
        height: 100px;
      }
      @include breakpoints(tablet) {
        height: auto;
      }
      .tronFlow_content {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        justify-content: space-between;
        .tronFlow_widget_info {
          p {
            font-size: 18px;
            color: $text-p-color;
            white-space: nowrap;
          }
          h2 {
            font-size: 14px;
            color: $text-p-color;
            margin-bottom: 10px !important;
          }
          a {
            color: $text-black;
            font-size: 13px;
            text-transform: capitalize;
            font-weight: 500;
            &:hover {
              color: $text-menu-active-color;
            }
          }
        }
        .tronFlow_widget_icon {
          order: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 0px;
          @include breakpoints(smLaptop) {
            margin-right: 10px;
          }
          svg {
            color: #fff;
            font-size: 30px;
            border-radius: 50px;
            padding: 10px;
          }
        }
      }
    }
  }
}
.converter_box_wrapper {
  width: 50%;
  @include breakpoints(tablet) {
    width: 100%;
  }
}
