@import "../abstract/variables";
@import "../responsive/breakpoints";

.custom_modal {
  position: fixed;
  background: $bg-white;
  border: 1px solid hsla(0deg, 0%, 88%, 0.38) !important;
  padding: 15px;
  width: 30%;
  height: 450px;
  overflow: auto;
  margin: 0 auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  transform-origin: center;
  z-index: 9999;
  @include breakpoints(largeLaptop) {
    width: 40%;
  }
  @include breakpoints(tablet) {
    width: 60%;
  }
  @include breakpoints(largeMobile) {
    width: 80%;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: $text-p-color;
      @include breakpoints(semiPortraitTablet) {
        font-size: 18px;
      }
    }
    span {
      font-size: 25px;
      cursor: pointer;
      user-select: none;
      color: $text-p-color;
    }
  }
}
// backdrop effect
.backdrop_effect {
  position: fixed;
  background: rgb(0 0 0 / 71%);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
}
.tronFlow_commol_modal_field {
  // modal input field
  .form_group {
    // display: flex;
    margin: 15px 0;
    @include breakpoints(portraitTablet) {
      display: inherit;
      margin: 0px;
    }
    .input_group {
      //   width: 45%;
      margin-top: 4px !important;
      @include breakpoints(portraitTablet) {
        width: auto;
        margin: 10px 0;
      }
    }
    .left {
      margin-right: 18px !important;
      // @include breakpoints(portraitTablet) {
      //   margin-right: 16px !important;
      // }
    }
    .right {
      margin-right: 15px !important;
    }
    .select_group {
      margin-right: 0px;
      margin-top: 10px;
      @include breakpoints(portraitTablet) {
        margin-right: 0px;
        margin: 10px 0;
      }
    }
    select {
      width: 100%;
      padding: 10px 8px;
      border: $border-header;
      outline: none;
      border-radius: 5px;
      color: $text-p-color;
      background: $bg-body;
    }
    label {
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 15px !important;
      color: $text-p-color;
      transition: all 0.3s ease-in-out;
    }
    input {
      width: 100%;
      padding: 8px 8px;
      border: $border-header;
      outline: none;
      border-radius: 5px;
      color: $text-p-color;
      background: $bg-body;
      transition: all 0.3s ease-in-out;
      margin-top: 3px;
    }
    input:disabled {
      background-color: $bg-body;
      border: none;
      cursor: no-drop;
      color: $text-p-color !important;
    }
  }
  .submit_button {
    button {
      margin-top: 10px;
      width: 130px;
      padding: 10px 8px;
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      color: #fff;
      border: none;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      @include breakpoints(portraitTablet) {
        width: 100%;
      }
    }
  }
  // transactio details
  .transaction_details {
    .group {
      margin-top: 10px;
      p {
        margin-top: 5px;
        strong {
          color: $text-p-color;
          font-size: 13px;
        }
        span {
          font-size: 13px;
          color: $text-p-color;
          padding: 2px 8px;
          border-radius: 5px;
        }
      }
    }
  }
}
