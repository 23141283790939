@import "../abstract/variables";
@import "../responsive/breakpoints";
.converter_field {
  margin-top: 30px !important;
  margin-bottom: 40px !important;
  h2 {
    text-transform: capitalize;
    font-size: 15px;
    margin-bottom: 15px !important;
    color: $text-p-color;
  }
  .converter_input_field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoints(tablet) {
      flex-wrap: wrap;
    }
    .trx_field {
      margin-left: 10px;
      @include breakpoints(tablet) {
        margin-left: 0px !important;
      }
      span {
        right: 0px !important;
      }
    }
    .input {
      position: relative;
      display: inherit;
      width: 50%;
      @include breakpoints(tablet) {
        margin-bottom: 20px !important;
        width: 100%;
      }
      input {
        font-size: 14px;
        padding: 15px 10px;
        padding-left: 50px;
        width: 100%;
        outline: none;
        border: $border-header;
        color: $text-p-color;
        background-color: $bg-body;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
      }
      span {
        position: absolute;
        left: 0;
        width: 45px;
        top: 0;
        height: 100%;
        background: $bg-thin-green;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        color: #fff;
        svg {
          font-size: 20px;
          color: #fff;
        }
      }
      strong {
        position: absolute;
        right: 0px !important;
        padding: 13px 15px !important;
        top: 0px !important;
        border: none !important;
        font-size: 14px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #fff;
      }
      .trx_amount_card {
        background: #c18f2e;
      }
      .usd_amount_card {
        background: $bg-thin-green;
      }
    }
  }
}
