@mixin scrolling {
  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg-white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dcdada;
  }
}
// chatting css
// .chatting_box {
//   position: fixed;
//   width: 270px;
//   height: 100%;
//   top: 0;
//   right: 0;
//   background-color: $bg-white;
//   z-index: 99999;
//   box-shadow: $box-shadow;
//   transform: translateX(320px);
//   transition: all 0.3s ease-in-out;
//   .mono_chatBox {
//     .title {
//       padding: 10px;
//       background-color: $bg-primary;
//       text-align: center;
//       h2 {
//         text-transform: capitalize;
//         font-size: 18px;
//         color: $text-white;
//       }
//     }
//     .chating_list {
//       height: 90vh;
//       overflow: auto;
//       @include scrolling();
//       ul {
//         li {
//           display: flex;
//           padding: 10px;
//           border-bottom: $border-1;
//           align-items: center;
//           cursor: pointer;
//           &:hover {
//             background-color: $bg-menu-active-color;
//           }
//           &:last-child {
//             border-bottom: none;
//           }
//           .image {
//             margin-right: 10px;
//             display: flex;
//             align-items: center;
//             position: relative;
//             img {
//               width: 40px;
//               height: 40px;
//               border-radius: 50px;
//             }
//             span {
//               position: absolute;
//               width: 7px;
//               height: 7px;
//               bottom: 0;
//               right: 0;
//               border-radius: 50%;
//               border: 2px solid white;
//             }
//           }
//           .chating_content {
//             h2 {
//               font-size: 15px;
//             }
//             p {
//               font-size: 13px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// message writing box css
.message_writing_box {
  position: fixed;
  right: 20px;
  bottom: 0;
  width: 278px;
  height: 420px;
  background-color: $bg-white;
  box-shadow: 0 0px 11px 0 rgb(182 182 182 / 62%);
  border: $border-1;
  border-radius: 5px;
  z-index: 9999;
  .heading {
    background-color: $bg-white;
    box-shadow: $box-shadow;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading_info {
      display: flex;
      align-items: center;
      position: relative;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
      }
      span {
        position: absolute;
        width: 7px;
        height: 7px;
        bottom: 0;
        left: 28px;
        border-radius: 50%;
        border: 2px solid white;
      }
      .name {
        h2 {
          font-size: 14px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    .cancel_btn {
      span {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .body {
    height: 310px;
    overflow: auto;
    @include scrolling();
    .messages {
      padding: 5px 10px;
      .flex_container {
        display: flex;
        margin: 20px 0;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50px;
        }
        .msg_cont {
          padding: 7px 10px;
          font-size: 14px;
          p {
            font-size: 12px;
          }
        }
      }
      .start {
        justify-content: start;
        .msg_cont {
          background-color: $bg-primary;
          color: $text-white;
          border-radius: 0px 20px 20px 20px;
          margin-left: 10px;
        }
      }
      .end {
        justify-content: end;
        .msg_cont {
          background-color: $bg-gray-white;
          color: $text-black;
          border-radius: 20px 0px 20px 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0px;
    padding: 10px;
    form {
      .form-group {
        display: flex;
        align-items: center;
        .message_input {
          font-size: 13px;
          padding: 10px 13px;
          outline: none;
          width: 168px;
          border-radius: 10px 0 0 10px;
          border: $border-1;
        }
        .message_btn {
          font-size: 13px;
          padding: 10px 12px;
          text-transform: uppercase;
          font-weight: 600;
          background: $bg-gray-white;
          border: $border-1;
          color: $text-primary;
          border-radius: 0 10px 10px 0;
          cursor: pointer;
          &:hover {
            background-color: $bg-menu-active-color;
          }
        }
      }
    }
  }
}
