@import "../responsive/breakpoints";
body {
  background-color: $bg-body !important;
  font-family: $font-family;
  position: relative;
  width: 100%;
  transition: all 0.3s ease-in-out;
  color: $text-default-color !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: $bg-body;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container {
  padding: 0 30px;
  max-width: 1280px;
  margin: auto;
}

.landingPage_container {
  padding: 0 80px;
  max-width: 1080px;
  margin: auto;
  @include breakpoints(tablet) {
    padding: 0 50px;
  }
  @include breakpoints(mdlgMobile) {
    padding: 0 30px;
  }
}
// :disabled {
//   background: #9b9696 !important;
//   cursor: no-drop !important;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}