@import "../../abstract/variables";
.mono_teamtree_title {
  h2 {
    color: $text-default-color;
  }
}
.tree img {
  width: 50px;
  border-radius: 50%;
}

.tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  display: flex;
  text-align: center;
}

.tree ul .inn_line {
  padding: 50px 0 0;
}

.tree ul::after {
  content: "";
  display: table;
  clear: both;
}

.tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 40px 0 0;
  width: 100%;
}

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 5px solid #ccc;
  width: 50%;
  height: 45px;
  z-index: -1;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 5px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 5px solid #ccc;
  border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

// .tree ul ul::before {
//   content: "";
//   position: absolute;
//   top: 10px;
//   left: 50%;
//   border-left: 5px solid #ccc;
//   width: 0;
//   height: 45px;
// }

.tree li a {
  padding: 0;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  color: $text-default-color;
  position: relative;
  top: 1px;
}

.tree li a .leader {
  h2 {
    font-size: 18px;
  }
  h5 {
    font-size: 14px;
    margin-top: -2px !important;
    margin-bottom: 4px !important;
    color: $text-sidebar-hover-color;
  }
}
.tree li a .vacant {
  h2 {
    font-size: 15px;
  }
  h5 {
    font-size: 14px;
    margin-bottom: 4px !important;
    color: $text-sidebar-hover-color;
    font-weight: 500;
  }
}

.tree li a .info {
  margin-top: -5px;
  font-size: 13px;
  font-weight: 700;
}
