.mono_sectiontable_wrapper {
  margin-top: 20px;
  .mono_sectiontable_card {
    padding: 25px;
    min-height: 250px;
    .mono_sectiontable_title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include breakpoints(tablet) {
        display: inherit;
      }

      h2 {
        font-size: 15px;
        text-align: left;
        color: $text-p-color;
        text-transform: uppercase;
        margin-bottom: 10px !important;
        position: relative;
        margin-left: 9px !important;
        font-weight: 500 !important;
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 83%;
          background: $text-menu-active-color;
          top: 3px;
          left: -8px;
          bottom: 0;
        }
      }
      .mono_sectiontable_balance {
        h2 {
          font-size: 20px;
          color: $text-default-color;
          background-color: $bg-menu-active-color;
          border-radius: 50px;
          padding: 10px 30px;
          @include breakpoints(tablet) {
            margin-top: 10px !important;
          }
        }
      }
      .searchbar_input {
        .spacial_search_input {
          padding: 10px;
          border-radius: 5px;
          outline: none;
          color: $text-p-color;
          background: $bg-body;
          font-size: 14px;
          border: none;
          border: $border-header;
          min-width: 200px;
        }
      }
    }
    .mono_sectiontable_table {
      .common_table {
        width: 100%;
      }
    }
    .mono_sectiontable_calculate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoints(tablet) {
        display: inherit;
      }
      h2 {
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0;
        text-align: center;
        border-radius: 50px;
        color: #fff;
        width: 40%;
        margin: auto;
        @include breakpoints(tablet) {
          width: 100%;
          font-size: 16px;
        }
      }
      .credit_balance {
        background: $bg-blue-linear-gradient;
        box-shadow: $box-shadow-sidebar-menu;
        @include breakpoints(tablet) {
          margin-bottom: 20px !important;
        }
      }
      .debit_balance {
        background: $bg-success-linear-gradient;
        box-shadow: $box-shadow-sidebar-menu;
      }
    }
  }
}
