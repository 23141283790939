@import "../abstract/variables";
@import "../responsive/breakpoints";
.mono_popover_wrapper {
  background: $bg-white !important;
  box-shadow: $box-shadow-2 !important;
  border: $border-header !important;
  position: relative;
  width: 300px;
  @include breakpoints(tablet) {
    width: calc(300px - 50px);
  }
  right: 0px;
  top: 55px;
  height: 350px;
  &::after {
    position: absolute;
    content: "";
    width: 13px;
    height: 13px;
    top: -7px;
    right: 12px;
    z-index: 9;
    transform: rotate(45deg);
    background: $bg-white;
    border-left: $border-header;
    border-top: $border-header;
    @include breakpoints(tablet) {
      width: 0px;
      height: 0px;
    }
  }
  @include breakpoints(tablet) {
    top: 46px;
  }
}
